import request from '@/utils/request'

export function addClick(params) {
  return request({
    url: '/viewer/addTapQuantity',
    method: 'post',
    params: params
  })
}


export function addAccessLog(params) {
  return request({
    url: '/viewer/addAccessLog',
    method: 'post',
    params: params
  })
}

// 获取品牌Logo（公开）
export function getBrandLogoApi(params) {
  return request({
    url: '/viewer/getBrandLogo',
    method: 'post',
    params: params
  })
}

// 获取列表（公开）
export function getViewerInfoApi(params) {
  return request({
    url: '/viewer/getModelViewerList',
    method: 'post',
    params: params
  })
}

// 获取品牌Logo（公开）
export function getProductInfoApi(params) {
  return request({
    url: '/colorx-tigerApi/proViewer/queryProductInfo',
    method: 'post',
    data: params
  })
}

export function getProductInfoBulkApi(params) {
  return request({
    url: '/colorx-tigerApi/proViewer/queryProductInfoBulk',
    method: 'post',
    data: params
  })
}


// 登录emersya
export function emersyaLogin() {
  return request({
    url: '/viewer/emersyaLogin',
    method: 'post'
  })
}

// 退出emersya
export function emersyaLogout(params) {
  return request({
    url: '/viewer/emersyaLogin',
    method: 'post',
    params: params
  })
}


// 获取所有材质（公开）
export function getAllMaterialGroupsApi(params) {
  return request({
    url: '/viewer/getModelViewerMaterialGroups',
    method: 'post',
    params: params
  })
}